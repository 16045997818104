// Import the necessary modules from tiptap core and prosemirror-state
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";

export const DisableEnter = Extension.create({
  name: "disableEnter",

  // Add a unique key to identify the plugin (optional but recommended)
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("disableEnter"),
        props: {
          handleKeyDown: (view, event) => {
            if (event.key === "Enter") {
              // Prevent the default enter behavior
              event.preventDefault();
              return true; // Indicates that this event has been handled
            }
            return false; // Let other keydown handlers run
          },
        },
      }),
    ];
  },
});

export const DisableEnterButAllowShiftEnter = Extension.create({
  name: "disableEnterButAllowShiftEnter",

  // Add a unique key to identify the plugin (optional but recommended)
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("disableEnterButAllowShiftEnter"),
        props: {
          handleKeyDown: (view, event) => {
            const { shiftKey: hasShiftKey } = event;
            // TODO: If shiftKey is pressed, make enter behave normally
            if (event.key === "Enter" && !hasShiftKey) {
              // Prevent the default enter behavior
              event.preventDefault();
              return true; // Indicates that this event has been handled
            }

            return false; // Let other keydown handlers run
          },
        },
      }),
    ];
  },
});
