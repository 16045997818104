import { TASKS } from "./tasks";

/* export const SHORTCUTS = {
  COMMAND: "/",
  TAG: "@",
}; */

export const SHORTCUTS = {
  // Write
  [TASKS.WRITE_BLOG_POST]: {
    condition: ({ lastMessageContent }) => lastMessageContent.includes(">outline-blog-post("),
    text: "Write a blog post",
  },
  [TASKS.WRITE_BLOG_POST_SEO_DESCRIPTION]: {
    condition: ({ lastMessageContent }) => lastMessageContent.includes(">blog-post("),
    text: "Write a meta description",
  },
  [TASKS.SUMMARIZE_TEXT]: {
    condition: ({ lastMessageContent }) => lastMessageContent.includes("Youtube Video Transcription:"),
    text: "Summarize the previous transcription",
  },

  // Suggest
  [TASKS.SUGGEST_BLOG_POST_COVER]: {
    condition: ({ lastMessageContent }) => lastMessageContent.includes(">blog-post("),
    text: "Suggest blog post cover",
  },
  [TASKS.SUGGEST_BLOG_POST_KEYWORDS]: {
    condition: ({ lastMessageContent }) => lastMessageContent.includes(">blog-post("),
    text: "Suggest blog post keywords",
  },
};

export const SHORTCUTS_LIST = [
  TASKS.WRITE_BLOG_POST,
  TASKS.WRITE_BLOG_POST_SEO_DESCRIPTION,
  TASKS.SUMMARIZE_TEXT,
  TASKS.SUGGEST_BLOG_POST_COVER,
  TASKS.SUGGEST_BLOG_POST_KEYWORDS,
];
