<template>
  <div>
    <div class="layout-wrapper">
      <div v-if="isShowingSidebar">
        <user-sidebar @open-register="isShowingRegister = true" @open-signin="isShowingSignin = true" />

        <div :style="`margin-left: ${WIDTH_USER_SIDEBAR}px;`" class="h-100 mt-4 bg-dark rounded-lg layout-almostfull-height">
          <div class="d-flex flex-column h-100" data-test="sidebar-project">
            <div class="mt-3 pl-3 pr-3">
              <h5 class="w-100 text-uppercase">
                {{ "Home" | __t("sidebar__project__home") }}
              </h5>
            </div>

            <div>
              <div class="p-2">
                <b-button @click="isShowingRegister = true" variant="success" class="w-100" pill>
                  {{ "Register" | __t("sidebar__project__register") }}
                </b-button>

                <b-button @click="isShowingSignin = true" variant="outline-black" class="mt-2 w-100" pill>
                  <span class="text-white">{{ "Sign In" | __t("sidebar__project__sign-in") }}</span>
                </b-button>

                <project-sidebar-category
                  @click="
                    taskKey = generateKey();
                    isShowingSidebar = false;
                  "
                  :name="'Tasks' | __t('projects__tasks__headline__tasks')"
                  class="mt-3 mb-1 w-100"
                />

                <p class="ml-3">
                  <small>
                    <em>{{
                      "Register or sign in to keep your tasks history."
                        | __t("projects__tasks__register-or-signin-to-keep-your-tasks")
                    }}</em>
                  </small>
                </p>
              </div>
            </div>

            <div class="ml-2 mt-auto">
              <div v-for="i in NAVIGATION_PUBLIC" :key="i.id">
                <project-sidebar-item
                  :name="i.name"
                  :icon="i.icon"
                  :color="i.color"
                  class="mb-1"
                  @click="isShowingRegister = true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!isShowingSidebar" class="peek-container">
        <div>
          <img
            @click="isShowingRegister = true"
            :src="require('@/assets/character/smiling.png')"
            height="80"
            alt="MeetLouis - Beta"
          />

          <b-button @click="isShowingRegister = true" class="float-right mt-4 mr-3 pl-3 pr-3 text-uppercase bg-lavender" pill>
            {{ "Register" | __t("sidebar__project__register") }}
          </b-button>
        </div>

        <div class="bg-dark pt-3 rounded-top-lg">
          <div>
            <h5 class="ml-3 mr-3">
              <b-button v-if="!isWizard" @click="taskKey = generateKey()" variant="transparent" class="mt-n2">
                <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
              </b-button>

              {{
                "Say Goodbye To Boring Tasks And Let Louis AI Handle Those"
                  | __t("projects__task__headline__say-goodbye-to-boring-tasks")
              }}
            </h5>
          </div>

          <div class="layout-almostfull-height">
            <project-task
              :key="taskKey"
              :is-wizard.sync="isWizard"
              :is-showing-previous.sync="isShowingPrevious"
              @open-login="isShowingSignin = true"
              @open-register="isShowingRegister = true"
            />
            <block-footer class="mt-50 mr-2 ml-2 text-center" />
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="isShowingSignin"
      @hidden="isShowingSignin = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <b-button @click="isShowingSignin = false" variant="transparent" class="float-right">
        <img src="@/assets/icons/close.svg" width="18" height="18" />
      </b-button>

      <user-login />
    </b-modal>

    <b-modal
      v-model="isShowingRegister"
      @hidden="isShowingRegister = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <b-button @click="isShowingRegister = false" variant="transparent" class="float-right">
        <img src="@/assets/icons/close.svg" width="18" height="18" />
      </b-button>

      <user-register>
        <p class="mb-0">
          {{
            "Insert your e-mail address to continue using Louis AI."
              | __t("auth__insert-your-email-address-to-continue-using-louis")
          }}
        </p>
      </user-register>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";

import { WIDTH_USER_SIDEBAR } from "@/constants/style";
import { NAVIGATION_PUBLIC } from "@/constants/project";

import { generateKey } from "@/helpers/utils";

import UserSidebar from "@/components/Sidebar/User/";
import UserLogin from "@/components/Auth/Login/";
import UserRegister from "@/components/Auth/Register/";
import ProjectSidebarCategory from "@/components/Sidebar/Project/Category";
import ProjectSidebarItem from "@/components/Sidebar/Project/Item";
import BlockFooter from "@/components/Layouts/FooterBlock";

import ProjectTask from "./Task/";

export default {
  name: "LayoutMobile",
  components: {
    BModal,
    BButton,
    UserSidebar,
    UserLogin,
    UserRegister,
    ProjectSidebarCategory,
    ProjectSidebarItem,
    BlockFooter,
    ProjectTask,
  },
  data: () => ({
    taskKey: generateKey(),

    isWizard: true,
    isShowingSidebar: false,
    isShowingSignin: false,
    isShowingRegister: false,
    isShowingPrevious: false,

    WIDTH_USER_SIDEBAR,
    NAVIGATION_PUBLIC,
  }),
  methods: {
    generateKey,
  },
};
</script>
