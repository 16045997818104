<template>
  <div>
    <editor-content @keyup.enter.native="handleEnter" :editor="editor" class="tiptap-reply" />
  </div>
</template>

<script>
import { Editor } from "@tiptap/core";
import { EditorContent } from "@tiptap/vue-2";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";
// import CharacterCount from "@tiptap/extension-character-count";
import HardBreak from "@tiptap/extension-hard-break";

import { toSlug } from "@/helpers/utils";
import { getTranslation } from "@/filters/translation";
import { DisableEnterButAllowShiftEnter } from "@/helpers/tiptap/DisableEnter";

export default {
  name: "TipTapMessage",
  components: { EditorContent },
  props: {
    id: String,
    placeholder: {
      type: String,
      default: "Type your message here...",
    },
    value: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      editor: null,
      localContent: null,
    };
  },
  mounted() {
    this.initializeEditor();

    this.$bus.on("focus-reply", this.handleFocus);
  },
  beforeDestroy() {
    this.editor.off("update", this.handleUpdate);

    this.editor.destroy();
  },
  methods: {
    initializeEditor() {
      const { value /* limit */ } = this;
      let { placeholder } = this;
      placeholder = getTranslation(placeholder, `placeholder__${toSlug(placeholder)}`);

      this.editor = new Editor({
        extensions: [
          Document,
          Text,
          Paragraph,
          HardBreak,
          DisableEnterButAllowShiftEnter,
          /* CharacterCount.configure({
            limit,
          }), */
          Placeholder.configure({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            placeholder,
          }),
        ],
        content: value,
      });

      this.editor.on("update", this.handleUpdate);
    },
    handleUpdate() {
      const content = this.editor.getHTML();
      this.localContent = content;
      this.$emit("update:value", content);
    },

    handleEnter({ shiftKey: hasShiftKey }) {
      // const { localContent: content } = this;

      if (!hasShiftKey) {
        this.$emit("success");
        this.localContent = "";
        this.editor.commands.setContent("");
      } else {
        this.scrollToBottom();
      }
    },
    async handleFocus() {
      await this.$nextTick();
      this.editor.commands.focus();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.tiptap-reply {
  max-height: 300px;
  overflow-y: auto;
}
</style>

<style lang="scss">
.tiptap-reply {
  .is-empty::before {
    position: absolute;
    content: attr(data-placeholder);
    color: #a9a9a9;
    pointer-events: none;
  }

  .ProseMirror:focus {
    outline: none;
    border: none;
  }

  p {
    margin-top: 10px;
  }

  blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    border-left: 5px solid #ccc;
    font-style: italic;
    quotes: "\201C""\201D""\2018""\2019";
  }

  blockquote:before {
    content: open-quote;
    font-size: 2em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    color: #ccc;
  }

  blockquote:after {
    content: close-quote;
  }

  blockquote p {
    display: inline;
    font-size: 1em;
  }
}

.tiptap.is-sending {
  .is-empty {
    display: none !important;
  }
}
</style>
