<template>
  <div>
    <b-badge
      v-for="(s, index) in shortcutsFiltered"
      :key="index"
      @click="handleShortcut(SHORTCUTS[s].text)"
      variant="primary"
      class="mr-3 pt-2 pb-2 pl-4 pr-4 text-l text-white font-weight-normal cursor-pointer"
      :class="{ 'mt-2 ml-auto mr-auto': isMobile }"
      pill
    >
      {{ SHORTCUTS[s].text | __t(`shortcuts__${toSlug(SHORTCUTS[s].text)}`) }}
    </b-badge>

    <div class="reply-container">
      <tip-tap-message
        @success="triggerSuccess"
        :value.sync="content"
        class="reply-input mt-4 p-3 rounded-lg"
        style="background-color: rgb(21, 23, 24)"
      />

      <b-button @click="triggerSuccess" variant="transparent" class="reply-icon">
        <img src="@/assets/icons/arrow-right.svg" width="20" height="20" />
      </b-button>
    </div>

    <small v-if="isSending">{{ "Louis AI is typing..." | __t("placeholder__louis-is-typing") }}</small>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { BButton, BBadge } from "bootstrap-vue";

import { CHATBOT_REPLY_FORMAT } from "@/constants/aiChat";
import { SHORTCUTS } from "@/constants/shortcuts";
import { TASKS_OPTIONS_COMMAND } from "@/constants/tasks";

import { filterShortcuts } from "@/helpers/shortcuts";
import { toSlug } from "@/helpers/utils";

import TipTapMessage from "@/components/Forms/TipTap/Message";

export default {
  name: "Reply",
  components: { BButton, BBadge, TipTapMessage },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    reply: {
      type: Object,
      default: null,
    },

    placeholder: {
      type: String,
      default: "Type your message here...",
    },

    isSending: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("projectsTasks", ["taskById", "itemsSortedByTaskId"]),
    projectId() {
      return this.$route.params.projectId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
    task() {
      const { taskId } = this;
      return this.taskById({ id: taskId });
    },
    action() {
      const { actionId } = this.task;
      return TASKS_OPTIONS_COMMAND.find(({ id }) => id === actionId);
    },
    shortcutsFiltered() {
      const { messages } = this;

      if (messages.length <= 1) return [];

      const lastMessageContent = messages[messages.length - 1].content;
      return filterShortcuts({ lastMessageContent });
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    content: null,
    format: CHATBOT_REPLY_FORMAT.TEXT,

    SHORTCUTS,
  }),
  watch: {
    isSending(newValue) {
      if (newValue) this.scrollToBottom();
    },
  },
  methods: {
    triggerSuccess() {
      const { reply, content, format, isSending } = this;
      if (!content || isSending) return;

      this.$emit("success", { content, format, reply });
      this.content = null;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },

    handleShortcut(content) {
      const { reply, format } = this;
      this.$emit("success", { content, format, reply });
    },

    toSlug,
  },
};
</script>

<style scoped>
.reply-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.reply-input {
  flex-grow: 1;
}

.reply-icon {
  width: 35px !important;
  height: 35px !important;
  padding: 7px;
  margin: auto;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  border-radius: 90px;
}

.reply-icon img {
  width: 20px;
  height: 20px;
  display: block;
  transition: transform 0.7s ease-in-out;
}

.reply-icon img:hover {
  transform: rotate(360deg);
}

.reply-icon.is-active,
.reply-icon:hover {
  background-color: #00d959;
  border-radius: 15px;
}
</style>
