import { SHORTCUTS_LIST, SHORTCUTS } from "@/constants/shortcuts";

export const filterShortcuts = ({ lastMessageContent }) => {
  const result = [];

  SHORTCUTS_LIST.forEach((shortcutId) => {
    const { condition } = SHORTCUTS[shortcutId];

    if (condition({ lastMessageContent })) result.push(shortcutId);
  });

  return result;
};
