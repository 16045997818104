<template>
  <div>
    <register-selection
      v-if="!currentStep"
      @continue-with-email-magiclink="currentStep = AUTH_STEPS.EMAIL_MAGICLINK"
      @continue-with-email-password="currentStep = AUTH_STEPS.EMAIL_PASSWORD"
    >
      <slot></slot>

      <template #footer>
        <slot name="footer"></slot>
      </template>
    </register-selection>

    <register-email-magiclink v-else-if="currentStep === AUTH_STEPS.EMAIL_MAGICLINK" @previous="currentStep = null" />
    <register-email-password v-else-if="currentStep === AUTH_STEPS.EMAIL_PASSWORD" @previous="currentStep = null" />
  </div>
</template>

<script>
import { AUTH_STEPS } from "@/constants/auth";

import RegisterSelection from "@/components/Auth/Register/Select";
import RegisterEmailMagiclink from "@/components/Auth/Register/EmailMagiclink";
import RegisterEmailPassword from "@/components/Auth/Register/EmailPassword";

export default {
  name: "Register",
  components: {
    RegisterSelection,
    RegisterEmailMagiclink,
    RegisterEmailPassword,
  },
  data() {
    return {
      currentStep: null,

      AUTH_STEPS,
    };
  },
};
</script>
