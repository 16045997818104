<template>
  <div>
    <div v-html="content" />

    <div
      v-for="(suggestion, index) in contentSuggestions"
      :key="index"
      class="mt-3 p-3 bg-transparent-success rounded-top-right-lg rounded-bottom-right-lg"
    >
      <p class="mb-0">
        <strong class="text-uppercase">Idea {{ index + 1 }}</strong>
      </p>

      <p v-html="suggestion.description" />

      <div class="text-right">
        <b-button @click="triggerCreateSuggestion(suggestion)" variant="success" :disabled="isSending" pill>
          Create Image
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE } from "@/store/actions";

import { BButton } from "bootstrap-vue";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { DEFAULT_REPLY } from "@/constants/tasks";

import { sanitizeTextWithContext } from "@/helpers/ai";

export default {
  name: "Suggestions",
  components: { BButton },
  props: {
    role: {
      type: String,
    },
    content: {
      type: String,
    },
    contentSuggestions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    taskId() {
      return this.$route.params.taskId;
    },
  },
  data: () => ({
    isSending: false,
  }),
  methods: {
    ...mapActions("projectsTasksDiscussions", { createMessage: CREATE }),
    async triggerCreateSuggestion(suggestionData) {
      this.isSending = true;

      const { projectId, taskId } = this;
      const { actionId } = suggestionData;

      const { firstMessage, storeDispatch, defaultData = {} } = DEFAULT_REPLY[actionId];

      const format = CHATBOT_MESSAGE_TYPE.FILE;
      const role = CHATBOT_ROLES.USER;
      const content = sanitizeTextWithContext(firstMessage, suggestionData);

      await this.createMessage({ projectId, taskId, role, format, content });

      await this.$store.dispatch(storeDispatch, { projectId, taskId, ...defaultData, ...suggestionData });

      this.isSending = false;
    },
  },
};
</script>
