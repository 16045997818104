<template>
  <b-row no-gutters>
    <div class="col-12 p-0 ui-chatbot-wrapper">
      <ui-chatbot
        @test="alert('success')"
        @reply="triggerReply"
        @retry="triggerRetry"
        :theme="CHATBOT_THEME"
        :messages="messages"
        :bot="DEFAULT_ASSISTANT"
        :component-reply="BaseChatReply"
        :dynamic-component="suggestions"
        :is-text-only="true"
        :is-showing-picture="isShowingPicture"
        :is-shifting-messages="false"
        :is-removable="false"
        :is-hiding-reply="isHidingReply"
        :is-sending="isSending"
        :is-loading="isLoading"
        :error-text="errorText"
        :max-number-of-characters="600000 * 2000"
        welcome-text=""
        :placeholder="`Insert your message here...` | __t('placeholder__insert-your-message-here')"
        class="ui-chatbot w-100"
        style="max-width: 825px"
        :style="styleUiChatbot"
      />
    </div>
  </b-row>
</template>

<script>
import { mapActions } from "vuex";
import { RESPOND_PUBLIC } from "@/store/actions";

import UiChatbot from "ui-chatbot";
import { BRow } from "bootstrap-vue";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_THEME, CHATBOT_ROLES, DEFAULT_WELCOME_TEXT, DEFAULT_ASSISTANT } from "@/constants/aiChat";

import BaseChatReply from "@/components/Base/Chat/Reply";
import BaseChatSuggestions from "@/components/Base/Chat/Suggestions";

export default {
  name: "Task",
  components: { BRow, UiChatbot },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },

    isHidingReply: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleUiChatbot() {
      const { isHidingReply } = this;
      if (isHidingReply) return ``;
      return "height: 100%";
    },

    isShowingPicture() {
      return true;
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    currentDocumentId: null,

    errorText: null,

    isLoading: false,
    isSending: false,

    BaseChatReply,
    suggestions: BaseChatSuggestions,

    CHATBOT_THEME,
    CHATBOT_MESSAGE_TYPE,
    DEFAULT_WELCOME_TEXT,
    DEFAULT_ASSISTANT,
  }),
  async mounted() {
    document.addEventListener("click", this.preventLinkGlobal);

    this.$bus.emit("focus-reply");
  },
  beforeDestroy() {
    document.removeEventListener("click", this.preventLinkGlobal);
  },
  methods: {
    ...mapActions("projectsTasksDiscussions", { respondPublicMessage: RESPOND_PUBLIC }),
    async triggerReply(lastMessage) {
      this.errorText = null;
      this.isSending = true;

      this.scrollToBottom();

      this.messages.push(lastMessage);

      if (lastMessage.role === CHATBOT_ROLES.USER) {
        const { isSuccess, errorCode /* ...data */ } = await this.respondPublicMessage({
          messages: this.messages,
        });

        // this.messages.push(data);

        if (!isSuccess) {
          this.errorText = errorCode;
          return;
        }
      }

      this.scrollToBottom(); // TODO: That should be handled in the ui-chatbot
      this.isSending = false;
    },

    async triggerRetry(lastMessage) {
      this.triggerReply(lastMessage, { isRetrying: true });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss">
.message__bubble {
  color: white !important;

  div {
    font-size: 1.3rem;
  }
}

.ui-chatbot {
  display: flex;
  flex-direction: column;

  .messages {
    flex-grow: 1;
  }

  /* .reply {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
  } */

  textarea {
    background-color: #272a34 !important;
    border-color: #9ba0a5 !important;
    color: white !important;
    font-size: 1.3rem;
  }

  textarea::placeholder {
    color: #ffffff;
    opacity: 0.5;
  }

  .document-id,
  .file-id {
    display: hidden;
  }
  .document-id::after,
  .file-id::after {
    content: "Click to view";
  }

  .document-name,
  .file-name {
    font-family: monospace;
    font-size: 1rem;
    background-color: #2b2d31;
    border-radius: 25px;
    padding: 4px 8px;
    // display: inline-flex;
    // align-items: center;
    // gap: 8px;

    h1 {
      font-family: monospace;
      font-size: 1rem;
    }
  }

  .file-name::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Fattached.svg?alt=media&token=6a738250-5a0e-4cab-9057-d9bbbecaf7d2");
    background-size: cover;
    margin-right: 10px;
  }
  .document-name::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/meet-louis.appspot.com/o/common%2Fdocument.svg?alt=media&token=066d7e93-9321-48d2-9c54-03fbf2774c28");
    background-size: cover;
  }
}
</style>
