<template>
  <b-row>
    <b-col cols="12" lg="6">
      <p class="m-0" data-cy="copyright">
        <small class="smaller"><strong>&copy;STOPLEADS OÜ</strong> - Sepapaja 6, Tallinn 15551 Estonia </small>
      </p>
    </b-col>

    <b-col cols="12" lg="6">
      <div class="text-center text-lg-right">
        <small class="smaller">
          <router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link> |
          <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link> |
          <router-link :to="{ name: 'TermsOfService' }">Terms of Service</router-link>
        </small>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  name: "FooterSection",
  components: { BRow, BCol },
  props: {
    theme: {
      type: String,
      default: "beige",
      validator: (v) => ["beige", "dark"].includes(v),
    },
  },
  computed: {
    isMobile() {
      return !this.$screen.is("md");
    },
  },
};
</script>
