<template>
  <div>
    <vue-lottie-player
      v-if="isLoading"
      name="loader"
      loop
      :animation-data="require('@/assets/animations/spinner.json')"
      class="ml-auto mr-auto"
      style="height: 100px"
    />

    <div v-else :key="keyReset">
      <b-row no-gutters>
        <b-col v-if="!isMobile" cols="12" md="12" class="text-right">
          <div class="mt-2 mr-2">
            <b-button @click="$emit('open-register')" variant="outline-success" class="mt-1 pl-3 pr-3" :disabled="isSending" pill>
              <img src="@/assets/icons/check.svg" width="20" height="20" class="mr-2" />
              {{ "Mark Complete" | __t(`projects__task__mark-complete`) }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <div
        v-if="isWizard"
        :class="{ 'pr-4': !isMobile }"
        class="w-100 h-100 d-flex align-items-center justify-content-center mt-4 pl-3 pr-3"
      >
        <spotlight
          @open-register="$emit('open-register')"
          @action="triggerCreateTask"
          :commands="taskOptionsCommandsFiltered"
          :is-hiding-input="true"
          :is-hiding-lock="isHidingLock"
        />
      </div>

      <div v-else>
        <task-chat :messages="messages" :is-hiding-reply="isHidingReply" />

        <!-- <task-first-message
          v-if="replyFormat === CHATBOT_MESSAGE_TYPE.FORM"
          :project-id="projectId"
          :task-id="taskId"
          :action-id="task?.actionId"
          :format="replyFormat"
          class="ml-4 mr-4"
        /> -->
      </div>

      <div v-if="isMobile" class="mt-50 p-3 bg-black rounded-top-lg">
        <p @click="$emit('open-register')" class="ml-3">
          <small>
            <em>
              {{
                "Register or sign in to keep your tasks history." | __t("projects__tasks__register-or-signin-to-keep-your-tasks")
              }}
            </em>
          </small>
        </p>

        <b-button @click="$emit('open-register')" variant="success" class="w-100" pill>
          {{ "Register" | __t("sidebar__project__register") }}
        </b-button>

        <b-button @click="$emit('open-login')" variant="outline-black" class="mt-2 w-100" pill>
          <span class="text-white">{{ "Sign In" | __t("sidebar__project__sign-in") }}</span>
        </b-button>
      </div>
    </div>

    <layout-spacer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { FETCH, CREATE, LISTEN, UNLISTEN } from "@/store/actions";

import { BRow, BCol, BButton } from "bootstrap-vue";
import VueLottiePlayer from "vue-lottie-player";
import cloneDeep from "lodash/cloneDeep";

import { CHATBOT_MESSAGE_TYPE, CHATBOT_ROLES } from "@/constants/aiChat";
import { DEFAULT_FIRST_MESSAGE, DEFAULT_REPLY, TASKS_OPTIONS_COMMAND } from "@/constants/tasks";

import { getTranslation } from "@/filters/translation";
import { toSlug, generateKey } from "@/helpers/utils";

import LayoutSpacer from "@/components/Layouts/Spacer";
import Spotlight from "@/components/Spotlight/";

import TaskChat from "./Chat";

export default {
  name: "Task",
  components: {
    BRow,
    BCol,
    BButton,
    VueLottiePlayer,
    LayoutSpacer,
    Spotlight,
    TaskChat,
  },
  computed: {
    replyFormat() {
      const { messages } = this;
      if (!messages.length) return null;

      return messages[messages.length - 1].reply?.format || null;
    },

    taskOptionsCommandsFiltered() {
      const { isCommandLocked } = this;
      const result = [];

      TASKS_OPTIONS_COMMAND.forEach((command) => {
        const { condition } = command;

        if (isCommandLocked) result.push({ ...command, isDisabled: true });
        else if (condition(true)) result.push(command);
        else result.push({ ...command, isDisabled: true });
      });

      return result;
    },

    isCommandLocked() {
      const { l } = this.$route.query; // Flag used only for paid ads to remove the lock and disable all commands
      return Boolean(l);
    },
    isHidingLock() {
      const { isCommandLocked } = this;
      return isCommandLocked;
    },
    isShowingWelcomeText() {
      const { messages } = this;
      return !messages.length;
    },
    isHidingReply() {
      const { replyFormat, task } = this;

      if (replyFormat === CHATBOT_MESSAGE_TYPE.FILE) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.DOCUMENT) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.URL) return true;
      if (replyFormat === CHATBOT_MESSAGE_TYPE.FORM) return true;
      if (task?.hasWorkingJob) return true;
      return false;
    },
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    messages: [],

    errorText: null,

    isWizard: true,
    isLoading: false,
    isSending: false,

    keyReset: generateKey(),

    CHATBOT_MESSAGE_TYPE,
    TASKS_OPTIONS_COMMAND,

    NODE_ENV: process.env.NODE_ENV,
  }),
  beforeDestroy() {
    this.$emit("update:is-wizard", true);
  },
  watch: {
    isWizard(newValue) {
      this.$emit("update:is-wizard", newValue);
    },
  },
  methods: {
    ...mapActions("projectsTasksDiscussions", {
      fetchMessages: FETCH,
      createMessage: CREATE,
      listenMessage: LISTEN,
      unlistenMessages: UNLISTEN,
    }),
    async triggerCreateTask(actionId) {
      this.isSending = true;

      const role = CHATBOT_ROLES.ASSISTANT;
      const format = CHATBOT_MESSAGE_TYPE.TEXT;
      const content = DEFAULT_FIRST_MESSAGE[actionId]
        ? getTranslation(
            cloneDeep(DEFAULT_FIRST_MESSAGE[actionId]),
            toSlug(`first-message__${cloneDeep(DEFAULT_FIRST_MESSAGE[actionId])}`)
          )
        : null;
      const reply = { format: DEFAULT_REPLY[actionId]?.format || null };

      this.messages.push({ id: generateKey(), content, role, format, reply });
      this.isWizard = false;
      // await this.createMessage({ content, role, format, reply });

      this.isSending = false;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const messageContainer = document.querySelector(".messages");
        const chatbotContainer = document.querySelector(".ui-chatbot");

        if (messageContainer) chatbotContainer.scrollTop = messageContainer.scrollHeight;
      });
    },

    toSlug,
  },
};
</script>
