<template>
  <div>
    <layout-desktop v-if="!isMobile" />
    <layout-mobile v-else />
  </div>
</template>

<script>
import LayoutDesktop from "./LayoutDesktop";
import LayoutMobile from "./LayoutMobile";

export default {
  name: "ProjectPublic",
  components: { LayoutDesktop, LayoutMobile },
  computed: {
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "@/assets/scss/_settings";

.app-container {
  height: 100%;
  overflow-y: scroll;
}

.top-section {
  background-color: #e2dfd7;
}

.limits__description {
  margin-top: 10px;
  .limits__description-label,
  .limits__description-max {
    text-transform: capitalize;
    font-family: "Quicksand";
    font-size: 14px;
    font-weight: 400;
    color: #b9bfd5;
  }
  .limits__description-value {
    font-size: 16px;
    font-family: "Quicksand";
    font-weight: 400;
    color: #7f00ff;
  }
}

// Use media queries if needed for specific styling on certain screen sizes
@media (max-width: map.get($grid-breakpoints, "sm")) {
  // styles for mobile screens go here
}

.title {
  font-weight: 600;
  font-size: 19px;
  margin-top: 5px;
  font-family: "Poppins";
}

.project-wrapper {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    height: calc(100vh - 100px);
  }
}
</style>
