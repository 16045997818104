<template>
  <div>
    <user-sidebar @open-signin="isShowingSignin = true" />

    <div
      :style="`margin-top: 50px; margin-left: ${WIDTH_USER_SIDEBAR}px;`"
      class="h-100 bg-dark rounded-top-left-lg project-wrapper"
    >
      <b-row no-gutters class="almostfull-height">
        <b-col cols="12" md="3" class="overflow-y-scroll">
          <div class="h-100 sidebar-project">
            <div class="d-flex flex-column h-100" data-test="sidebar-project">
              <div class="mt-3 pl-3 pr-3">
                <h5 class="w-100 text-uppercase">
                  {{ "Home" | __t("sidebar__project__home") }}
                </h5>
              </div>

              <div>
                <div class="p-2">
                  <b-button @click="isShowingRegister = true" variant="success" class="w-100" pill>
                    {{ "Register" | __t("sidebar__project__register") }}
                  </b-button>
                  <b-button @click="isShowingSignin = true" variant="outline-black" class="mt-2 w-100" pill>
                    <span class="text-white">{{ "Sign In" | __t("sidebar__project__sign-in") }}</span>
                  </b-button>

                  <project-sidebar-category
                    @click="keyTask = generateKey()"
                    :name="'Tasks' | __t('projects__tasks__headline__tasks')"
                    class="mt-3 mb-1 w-100"
                  />

                  <p class="ml-3" @click="isShowingRegister = true">
                    <small>
                      <em>{{
                        "Register or sign in to keep your tasks history."
                          | __t("projects__tasks__register-or-signin-to-keep-your-tasks")
                      }}</em>
                    </small>
                  </p>
                </div>
              </div>

              <div class="ml-2 mt-auto">
                <div v-for="i in NAVIGATION_PUBLIC" :key="i.id">
                  <project-sidebar-item
                    :name="i.name"
                    :icon="i.icon"
                    :color="i.color"
                    class="mb-1"
                    @click="isShowingRegister = true"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="9">
          <project-task @open-signin="isShowingSignin = true" @open-register="isShowingRegister = true" :key="keyTask" />
          <block-footer class="mt-50" />
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-model="isShowingSignin"
      @hidden="isShowingSignin = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <user-login />
    </b-modal>

    <b-modal
      v-model="isShowingRegister"
      @hidden="isShowingRegister = false"
      body-bg-variant="dark"
      centered
      hide-header
      hide-footer
      size="lg"
    >
      <user-register>
        <p class="mb-0">
          {{
            "Insert your e-mail address to continue using Louis AI."
              | __t("auth__insert-your-email-address-to-continue-using-louis")
          }}
        </p>
      </user-register>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BRow, BCol, BButton } from "bootstrap-vue";

import { NAVIGATION_PUBLIC } from "@/constants/project";
import { WIDTH_USER_SIDEBAR } from "@/constants/style";

import { generateKey } from "@/helpers/utils";

import UserSidebar from "@/components/Sidebar/User/";
import UserLogin from "@/components/Auth/Login/";
import UserRegister from "@/components/Auth/Register/";
import ProjectSidebarCategory from "@/components/Sidebar/Project/Category";
import ProjectSidebarItem from "@/components/Sidebar/Project/Item";
import BlockFooter from "@/components/Layouts/FooterBlock";

import ProjectTask from "./Task/";

export default {
  name: "LayoutDesktop",
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    UserSidebar,
    UserLogin,
    UserRegister,
    ProjectSidebarCategory,
    ProjectSidebarItem,
    ProjectTask,
    BlockFooter,
  },
  data: () => ({
    keyTask: generateKey(),

    isShowingSignin: false,
    isShowingRegister: false,

    WIDTH_USER_SIDEBAR,
    NAVIGATION_PUBLIC,
  }),
  methods: {
    generateKey,
  },
};
</script>

<style lang="scss" scoped>
.almostfull-height {
  height: calc(100vh - 50px);
  overflow-y: scroll;
}
</style>
