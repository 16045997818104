<template>
  <div>
    <login-selection
      v-if="!currentStep"
      @continue-with-email-magiclink="currentStep = AUTH_STEPS.EMAIL_MAGICLINK"
      @continue-with-email-password="currentStep = AUTH_STEPS.EMAIL_PASSWORD"
      @continue-with-google="triggerLoginGoogle"
      :is-loading="isLoading"
    />

    <login-email-magiclink v-else-if="currentStep === AUTH_STEPS.EMAIL_MAGICLINK" @previous="currentStep = null" />
    <login-email-password v-else-if="currentStep === AUTH_STEPS.EMAIL_PASSWORD" @previous="currentStep = null" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { LOGIN_BY_GOOGLE } from "@/store/actions";

import { AUTH_STEPS } from "@/constants/auth";
import { AUTH_ERROR_MESSAGE } from "@/constants/local-storage";

import LoginSelection from "@/components/Auth/Login/Select";
import LoginEmailMagiclink from "@/components/Auth/Login/EmailMagiclink";
import LoginEmailPassword from "@/components/Auth/Login/EmailPassword";

export default {
  name: "Login",
  components: {
    LoginSelection,
    LoginEmailMagiclink,
    LoginEmailPassword,
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },
  data() {
    return {
      currentStep: null,

      isLoading: false,

      AUTH_STEPS,
    };
  },
  created() {
    this.errorMessage = window.localStorage.getItem(AUTH_ERROR_MESSAGE) || null;
    window.localStorage.removeItem(AUTH_ERROR_MESSAGE);
  },
  watch: {
    isAuthenticated() {
      const { isAuthenticated } = this;
      if (isAuthenticated) this.$router.push({ name: "Projects" });
    },
  },
  methods: {
    ...mapActions("auth", { loginByGoogle: LOGIN_BY_GOOGLE }),
    async triggerLoginGoogle() {
      this.isLoading = true;
      this.errorMessage = null;
      const { isSuccess } = await this.loginByGoogle();

      if (!isSuccess) {
        this.errorMessage = "Login failed. Please contact us at hello@meetlouis.ai";
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/_settings";
.divider-or {
  text-align: center;
  font-weight: bolder;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  span {
    margin: 0 10px;
  }
  &::before,
  &::after {
    content: "";
    display: block;
    height: 2px;
    flex-shrink: 0;
    flex-grow: 1;
  }
}
.theme--default .divider-or {
  color: $gray-light-100;
  &::before,
  &::after {
    background-color: $gray-light-100;
  }
}
</style>
